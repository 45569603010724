import api from '../axios.service'

export default {
  get(data,status=null) {

    var url =`config/list?search=${data.search}&size=${data.size}&page=${data.page}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`;
    if (status){
      url += '&status='+status
    }
    return api.get(url)
  },
  getConfigOrder(data,status=null) {


    //   axios.get('{{url('/api/configOrder')}}'+'?order_id='+order_id+'&edit=1')

    var url =`config/order/${data.order_id}?edit=1`;


    return api.get(url)
  },
  apiTransportExpensePriceRate(amount, transportTypeID) {
    return api.get(`/apiTransportExpensePriceRate?amount=${amount}&transport_type_id=${transportTypeID}`)
  },
  show(data, id) {
    const params = new URLSearchParams()
    params.append('search', data.search)

    return api.get(`/transportType/${id}`)
  },
  post(data) {

    var getFormData = data;
    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }

    return api.post('config/store',params,{})
  },
  put(data) {
    var getFormData = data;
    let params = new FormData();
    params.append('_method','PUT')
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }

    return api.post(`config/update/${data.id}`,params,{})
  },
  updateStatus(id) {
    let params = new FormData();
    params.append('_method','PUT')

    return api.post(`config/update_config_status/${id}`,params,{})
  },
  delete(data) {
    const params = new URLSearchParams()
    params.append('search', data.search)

    return api.delete('/transportType')
  },
}

<template>
  <v-data-table
    :headers="headers"
    :items="configs"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <div class="d-flex justify-space-between"> -->
            <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="close"
            >
              เพิ่มการตั้งค่าใช้จ่ายใน Order
            </v-btn>
            <!-- </div> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      label="ชื่อตั้งค่า"
                    ></v-text-field>
                    <small
                      class="validate-err"
                      v-for="(err,index) in v$.editedItem.name.$silentErrors"
                      v-if="v$.editedItem.name.$error">{{ index > 0 ? ', ' : '' }}
                      {{ err.$message }}</small>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      type="number"
                      v-model="editedItem.value"
                      label="จำนวน/ค่า"
                    ></v-text-field>
                    <small
                      class="validate-err"
                      v-for="(err,index) in v$.editedItem.value.$silentErrors"
                      v-if="v$.editedItem.value.$error">{{ index > 0 ? ', ' : '' }}
                      {{ err.$message }}</small>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12">
                    <v-textarea
                      v-model="editedItem.detail"
                      label="รายละเอียด"
                    ></v-textarea>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="12">
                    <v-checkbox
                      value="Y"
                      hide-details
                      dense
                      v-model="editedItem.status"
                      title="สถานะ เปิด/ปิด"
                      label="สถานะ (เปิด/ปิด)"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
<!--        <v-dialog-->
<!--          v-model="dialogDelete"-->
<!--          max-width="600px"-->
<!--        >-->
<!--          <v-card>-->
<!--            <v-card-title class="text-h5 mb-5">-->
<!--              Are you sure you want to delete this item?-->
<!--            </v-card-title>-->
<!--            <v-card-actions>-->
<!--              <v-spacer></v-spacer>-->
<!--              <v-btn-->
<!--                color="error"-->
<!--                outlined-->
<!--                @click="closeDelete"-->
<!--              >-->
<!--                Cancel-->
<!--              </v-btn>-->
<!--              <v-btn-->
<!--                color="primary"-->
<!--                @click="deleteItemConfirm"-->
<!--              >-->
<!--                OK-->
<!--              </v-btn>-->
<!--              <v-spacer></v-spacer>-->
<!--            </v-card-actions>-->
<!--          </v-card>-->
<!--        </v-dialog>-->
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page-1)*serverOptions.itemsPerPage) + index +1 }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-checkbox
        value="Y"
        hide-details
        dense
        @click="updateStatus(item.id)"
        v-model="item.status">
      </v-checkbox>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
<!--      <v-icon-->
<!--        small-->
<!--        @click="deleteItem(item)"-->
<!--      >-->
<!--        {{ icons.mdiDelete }}-->
<!--      </v-icon>-->
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_config from '../../../services/config'
import Swal from "sweetalert2";
import {email, minLength, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";

export default {
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    serverOptions : {
      page: 1,
      itemsPerPage: 10,
    },
    total : 0,
    configs: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index', sortable: false
      },
      { text: 'ชื่อตั้งค่า', value: 'name' },
      { text: 'จำนวน/ค่า', value: 'value' },
      { text: 'สถานะ (เปิด/ปิด)', value: 'status' },
      { text: 'แก้ไขข้อมูล', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name : '',
      value : '',
    },
    defaultItem: {
      name : '',
      value : '',
    },
  }),
  validations() {
    return {
      editedItem: {
        name: { required },
        value: { required },
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม ตั้งค่าใช้จ่ายใน Order' : 'แก้ไข ตั้งค่าใช้จ่ายใน Order'
    },
  },

  watch: {
    serverOptions : {
      handler(){
        this.getConfig()
      },
    },
    search : {
      handler(){
        this.getConfig()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getConfig()
  },

  methods: {
    editItem(item) {
      this.v$.$reset();
      this.editedIndex = this.configs.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true

    },

    // deleteItem(item) {
    //   this.editedIndex = this.configs.indexOf(item)
    //   this.editedItem = { ...item }
    //   this.dialogDelete = true
    // },
    updateStatus(id){
      this.$store.state.isLoading = true
      instance_config.updateStatus(id).then(res => {
        Swal.fire({
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500
        })
        this.$store.state.isLoading = false

      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false

      });
    },
    // deleteItemConfirm() {
    //   instance_config.packageDestroy(this.editedItem).then(res => {
    //
    //     this.configs.splice(this.editedIndex, 1)
    //   }).catch(err => {
    //
    //   })
    //   this.closeDelete()
    // },

    close() {
      this.v$.$reset();
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.$store.state.isLoading = true

      var vm =this;
      let idx = vm.editedIndex
      let item = vm.editedItem
      if (this.editedIndex > -1) {
        instance_config.put(this.editedItem)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            },150)
          // Object.assign(vm.configs[idx], item)
            this.getConfig()
            this.close()
        }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      } else {
        instance_config.post(this.editedItem)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            },150)

            this.getConfig()
            this.close()
          // this.configs.push(item)
        }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      }
    },
    getConfig() {
      this.$store.state.isLoading = true
      var data = {
        search: this.search,
        size : this.serverOptions.itemsPerPage,
        page : this.serverOptions.page,
        sortBy : this.sortBy ?? "",
        sortDesc : this.sortDesc ?? ''
      }

      instance_config.get(data).then(res => {
        this.configs = res.data.data.data;
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
